/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
//animated logo
function animate_logo() {
    var waypoint = new Waypoint({
      element: document.getElementById('animate-logo'),
      handler: function(direction) {
        if (direction === 'down') {
          //console.log ("down");
          //show logo 
          $('.slideOut').show();
          $('.logo-right').animate({
            right: '-=100%',
            bottom: '-=100%',
            opacity: 0
          }, 400);
          setTimeout(function() {
            $('.logo-left').animate({
              left: '-=100%',
              bottom: '-=100%',
              opacity: 0
            }, 400);
          }, 300);
        } else {
          //console.log ("up");
          //show logo 
          //$('.brand').hide();
          $('.logo-left').animate({
            left: '+=100%',
            bottom: '+=100%',
            opacity: 1
          }, 400);
          setTimeout(function() {
            $('.logo-right').animate({
              right: '+=100%',
              bottom: '+=100%',
              opacity: 1
            }, 400);
          }, 300);
        }
        //end down
      }
    });
  }
  (function($) {
    var dir_url = PostID.template_url;
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
      // All pages
      'common': {
        init: function() {
          // JavaScript to be fired on all pages
          $(window).on("resize", function() {
            //slide out logo
            $('.slideOut').hover(function() {
              $('.blue-giant-type').stop(true).animate({
                left: '90px',
                opacity: 1.0,
              }, 300);
            }, function() {
              $('.blue-giant-type').stop(true).animate({
                left: '30px',
                opacity: 0.0,
              }, 200);
            });
            if ($(window).width() < 769) {
              //slide out logo
              $('.slideOut').hover(function() {
                $('.blue-giant-type').stop(true).animate({
                  left: '90px',
                  opacity: 1.0,
                }, 300);
              }, function() {
                $('.blue-giant-type').stop(true).animate({
                  left: '30px',
                  opacity: 0.0,
                }, 200);
              });
            }
            // Invoke the resize event immediately
          }).resize();
          $(".data_color").click(function() {
            var overlay_color = $(this).attr("data-overlay-color");
            $('.animsition-overlay-slide').css('background-color',
              overlay_color);
          });
          //find the O in the menu and wrap a span around it!
          $(".menu-item>a:contains('o')").html(function(_, html) {
            return html.replace(/(o)/g, '<span>$1</span>');
          });
          //find the O in the menu and wrap a span around it!
          $(".intro-header .desc-txt>h1:contains('o')").html(function(_,
            html) {
            return html.replace(/(o)/g, '<span>$1</span>');
          });
          //Equal Height
          (function($) {
            "use strict";
            $.fn.equalHeights = function(widthThreshold) {
              var self = this,
                nodeObjects = [],
                heights = [],
                tallest;
              $(window).on("load resize", function() {
                self.children().each(function(i) {
                  $(this).css("height", "");
                  var height = $(this).height(),
                    paddingTop = Math.ceil(parseFloat($(this)
                      .css("padding-top"))),
                    paddingBottom = Math.ceil(parseFloat($(
                      this).css("padding-bottom"))),
                    borderTop = Math.ceil(parseFloat($(this).css(
                      "border-top-width"))),
                    borderBottom = Math.ceil(parseFloat($(
                      this).css("border-bottom-width"))),
                    totalHeight = height + paddingTop +
                    paddingBottom + borderBottom + borderTop;
                  nodeObjects[i] = {
                    height: height,
                    paddingTop: paddingTop,
                    paddingBottom: paddingBottom,
                    borderTop: borderTop,
                    borderBottom: borderBottom,
                    totalHeight: totalHeight
                  };
                  heights[i] = totalHeight;
                });
              });
              $(window).on("load resize", function() {
                if (widthThreshold && $(window).width() <
                  widthThreshold) {
                  return false;
                }
                self.children().each(function(i) {
                  var diff,
                    oldHeight = $(this).height(),
                    newHeight;
                  tallest = Math.max.apply(Math, heights);
                  diff = tallest - nodeObjects[i].totalHeight;
                  newHeight = oldHeight + diff;
                  $(this).height(newHeight);
                });
              });
            };
          }(jQuery));
          //EQ Hieghts        
          $(".equalHeights").equalHeights();
          //size hero section to window height
          $(window).on("resize", function() {
            $('.vh-height').height($(window).height());
            // Invoke the resize event immediately
          }).resize();
          //menu
          $(function() {
            $("#menu").mmenu({
              navbar: false,
              "offCanvas": {
                "position": "right"
              },
            });
          });
          //var API = $("#menu").data( "mmenu" );
          $(".close-menu").click(function() {
            $("#menu").data("mmenu").close();
          });
        },
        finalize: function() {
          // JavaScript to be fired on all pages, after page specific JS is fired
        }
      },
      // Home page
      'home': {
        init: function() {
          // JavaScript to be fired on the home page
          //wow
          var wow = new WOW({
            boxClass: 'wow', // animated element css class (default is wow)
            animateClass: 'animated', // animation css class (default is animated)
            mobile: false, // trigger animations on mobile devices (default is true)
            live: true, // act on asynchronously loaded content (default is true)
            callback: function(box) {
              // the callback is fired every time an animation is started
              // the argument that is passed in is the DOM node being animated
            },
            scrollContainer: null // optional scroll container selector, otherwise use window
          });
          wow.init();
          //dropdown menu
          var bottom = $('.intro').offset().top - 400;
          $(window).scroll(function() {
            if ($(this).scrollTop() > bottom) {
              $(".intro").addClass('active hover');
            } else {
              $(".intro").removeClass('active hover');
            }
          });
          $(".animsition-overlay").animsition({
            inClass: 'overlay-slide-in-top',
            outClass: 'overlay-slide-out-top',
            inDuration: 1500,
            outDuration: 800,
            linkElement: '.animsition-link',
            // e.g. linkElement: 'a:not([target="_blank"]):not([href^=#])'
            loading: true,
            loadingParentElement: 'html', //animsition wrapper element
            loadingClass: 'animsition-loading',
            loadingInner: '', // e.g '<img src="loading.svg" />'
            timeout: false,
            timeoutCountdown: 5000,
            onLoadEvent: true,
            browser: ['animation-duration',
                        '-webkit-animation-duration'
                    ],
            // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
            // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
            overlay: true,
            overlayClass: 'animsition-overlay-slide',
            overlayParentElement: 'html',
            transition: function(url) {
              window.location.href = url;
            }
          }).one('animsition.inStart', function() {
            //$('body').removeClass('bg-init');
            console.log('event -> inStart');
          }).one('animsition.inEnd', function() {
            console.log('event -> inEnd');
            //hide slick arrow onload
            $('.slick-arrow').hide();
            $('.next-arrow').hide();
            $('.lg-circle h2').animate({
              opacity: 0
            }).hide();
            $('.bottom-center').animate({
              right: '+=100%',
              bottom: '+=100%',
              opacity: 1
            }, 600).show(600);
            setTimeout(function() {
              $('.bottom-left').animate({
                left: '+=100%',
                bottom: '+=100%',
                opacity: 1
              }, 600).show(600);
            }, 500);
            setTimeout(function() {
              $('.top-left').animate({
                left: '+=100%',
                top: '+=100%',
                opacity: 1
              }, 600).show(600);
            }, 900);
            setTimeout(function() {
              $('.lg-circle').animate({
                right: '+=150%',
                top: '+=150%',
                opacity: 1
              }, 600).show(600);
              $('.next-arrow').show();
              $('.lg-circle h2').animate({
                opacity: 1
              }, 1500).show(1500);
              //$('.next-arrow').animate({right: '+=100%',opacity: 1,}, 700);
            }, 1300);
          });
          //end
          //overlay video
          $('.overlay-button').click(function(e) {
            e.stopPropagation();
            //open the modal
            $("#modal-overlay").addClass("active");
            //grab the vimeo id value
            var id = $(this).data('value');
            $('.ajax-container').css({
              display: 'inline-block'
            }).animate({
              "opacity": 1,
              'min-height': "450px"
            }, 'slow').html('<div class="rectangle-bounce"></div>');
            //start ajax request
            $.ajax({
              url: "/wp-content/themes/bluegiant/lib/vimeo-data.json",
              type: "GET",
              data: {
                "id": id
              },
              dataType: "json",
              success: function(data) {
                //console.log(id);
                //console.log(data);
                for (var i = 0, len = data.length; i < len; i++) {
                  // console.log(data[i].id);
                  if (data[i].id == id) {
                    //console.log(data)
                    console.log(data[i]['id']);
                    var vimeoID = data[i]['id'];
                    var title = data[i]['title'];
                    var desc = data[i]['description'];
                    htmlData =
                      '<a id="hamburger" class="close-modal"><span></span></a><iframe src="https://player.vimeo.com/video/' +
                      vimeoID +
                      '?api=1" id="vimeo_frame" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>' +
                      '<div class="vimeo-desc text-center hidden"><a id="play">PLAY</span></div>';
                    $('.ajax-container').html(htmlData);
                    vimeoplayerAPI();
                  }
                }
              }
            });
            //end ajax
          });
          //end of json ajx call
          function vimeoplayerAPI() {
              var iframe = $('#vimeo_frame')[0];
              var player = $f(iframe);
              //alert('play');
              setTimeout(function() {
                $('#play').click();
              }, 500);
              $('#play').click(function() {
                player.api('play');
              });
              $('.close-modal').click(function() {
                if ($("#modal-overlay").hasClass("active")) {
                  player.api('pause');
                  $("#modal-overlay").removeClass("active");
                }
              });
            }
            //end function
          setTimeout(function() {
            var iframe = document.getElementsByTagName('iframe')[0];
            iframe.contentWindow.postMessage(
              '{"method":"setVolume", "value":0}', '*');
          }, 1000);
          // vimeo fullhttps://vimeo.com/159388800
          var options = {
              videoId: '159388800',
              parameters: {
              autopause: 0,
              autoplay: 1,
              badge: 0,
              byline: 0,
              color: '000',
              loop: 1,
              player_id: 'player1',
              portrait: 0,
              title: 0,
            }
          };
          $('#vimeo_wrapper').vimelar(options);
          //slick slider
          var w = Math.max(document.documentElement.clientWidth, window.innerWidth ||
            0);
          var isFade = w > 768 ? true : false;
          $('.slick-slider').slick({
            speed: 500,
            fade: isFade,
            cssEase: 'linear',
            draggable: false,
          });
          //hide logo on start of page
          $('.slideOut').hide();
          $('.next-arrow').on('click', animateEle);

          function animateEle(e) {
              //on click move to next slide
              //check to make sure all elements are visibile
              if ($('.bottom-center').is(":visible")) {
                //hide animation trigger
                $('.next-arrow').hide();
                $('.bottom-center').animate({
                  right: '-=100%',
                  bottom: '-=100%',
                  opacity: 0
                }, 400);
                $('.bottom-left').animate({
                  left: '-=100%',
                  bottom: '-=100%',
                  opacity: 0
                }, 400);
                $('.top-left').animate({
                  left: '-=100%',
                  top: '-=100%',
                  opacity: 0
                }, 400);
                $('.lg-circle').animate({
                  right: '+=25%',
                  top: '+=25%',
                  width: '-0%',
                  height: '-0%',
                  opacity: 0
                }, 300).hide(200);
                setTimeout(function() {
                  $(".slick-slider").slick('slickNext');
                  //show logo on next slide
                  $('.brand').show();
                }, 1000);
              } else {
                //we can move with running out animation
                $(".slick-slider").slick('slickNext');
              }​
            }
            //hide slick arrow onload
          $('.slick-arrow').hide();
          // On before slide change
          $('.slick-slider').on('beforeChange', function(event, slick,
            currentSlide, nextSlide) {
            //console.log(nextSlide);
            if (nextSlide === 0) {
              //hide logo
              $('.brand').hide();
              //let's put everything back together 
              //$('.next-arrow').animate({right: '+=100%', opacity: 1,}, 700);
              //hide slick arrow onload
              $('.slick-arrow').hide();
              $('.bottom-center').animate({
                right: '+=100%',
                bottom: '+=100%',
                opacity: 1
              }, 400).show(400);
              setTimeout(function() {
                $('.bottom-left').animate({
                  left: '+=100%',
                  bottom: '+=100%',
                  opacity: 1
                }, 400).show(400);
              }, 500);
              setTimeout(function() {
                $('.top-left').animate({
                  left: '+=100%',
                  top: '+=100%',
                  opacity: 1
                }, 400).show(400);
              }, 700);
              setTimeout(function() {
                $('.lg-circle').animate({
                  right: '-=25%',
                  top: '-=25%',
                  width: '+50vh',
                  height: '+50vh',
                  opacity: 1
                }, 400).show(400);
                $('.next-arrow').show();
              }, 1100);
            }
            if (nextSlide === 1) {
              //show regular slick
              $('.slick-arrow').show();
              //show company logo
              $('.slideOut').show();
            }
          });
        },
        finalize: function() {
          // JavaScript to be fired on the home page, after the init JS
        }
      },
      // work page, note the change from about-us to about_us.
      'post_type_archive_work': {
        init: function() {
          // JavaScript to be fired on the work page
          animate_logo();
          $(".animsition-overlay").animsition({
            inClass: 'overlay-slide-in-top',
            outClass: 'overlay-slide-out-top',
            inDuration: 1500,
            outDuration: 800,
            linkElement: '.animsition-link',
            // e.g. linkElement: 'a:not([target="_blank"]):not([href^=#])'
            loading: true,
            loadingParentElement: 'html', //animsition wrapper element
            loadingClass: 'animsition-loading',
            loadingInner: '', // e.g '<img src="loading.svg" />'
            timeout: false,
            timeoutCountdown: 5000,
            onLoadEvent: true,
            browser: ['animation-duration',
                        '-webkit-animation-duration'
                    ],
            // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
            // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
            overlay: true,
            overlayClass: 'animsition-overlay-slide',
            overlayParentElement: 'html',
            transition: function(url) {
              window.location.href = url;
            }
          }).one('animsition.inStart', function() {
            var wow = new WOW({
              boxClass: 'wow', // animated element css class (default is wow)
              animateClass: 'animated', // animation css class (default is animated)
              mobile: false, // trigger animations on mobile devices (default is true)
              live: true, // act on asynchronously loaded content (default is true)
              callback: function(box) {
                // the callback is fired every time an animation is started
                // the argument that is passed in is the DOM node being animated
              },
              scrollContainer: null // optional scroll container selector, otherwise use window
            });
            wow.init();
            //$('body').removeClass('bg-init');
            console.log('event -> inStart');
          }).one('animsition.inEnd', function() {
            console.log('event -> inEnd');
          });
          //end
        }
      },
      'single_work': {
        init: function() {
          // JavaScript to be fired on the work page
          animate_logo();
          setTimeout(function() {
            $('.hero-height').addClass('active');
          }, 300);
          $(window).on("resize", function() {
            $('.hero-height').height($(window).height());
            // Invoke the resize event immediately
          }).resize();
          $(".animsition-overlay").animsition({
            inClass: 'overlay-slide-in-top',
            outClass: 'overlay-slide-out-top',
            inDuration: 1500,
            outDuration: 800,
            linkElement: '.animsition-link',
            // e.g. linkElement: 'a:not([target="_blank"]):not([href^=#])'
            loading: false,
            loadingParentElement: 'html', //animsition wrapper element
            loadingClass: 'animsition-loading',
            loadingInner: '', // e.g '<img src="loading.svg" />'
            timeout: false,
            timeoutCountdown: 5000,
            onLoadEvent: true,
            browser: ['animation-duration',
                        '-webkit-animation-duration'
                    ],
            // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
            // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
            overlay: false,
            overlayClass: 'animsition-overlay-slide',
            overlayParentElement: 'html',
            transition: function(url) {
              window.location.href = url;
            }
          }).one('animsition.inStart', function() {
            var wow = new WOW({
              boxClass: 'wow', // animated element css class (default is wow)
              animateClass: 'animated', // animation css class (default is animated)
              mobile: false, // trigger animations on mobile devices (default is true)
              live: true, // act on asynchronously loaded content (default is true)
              callback: function(box) {
                // the callback is fired every time an animation is started
                // the argument that is passed in is the DOM node being animated
              },
              scrollContainer: null // optional scroll container selector, otherwise use window
            });
            wow.init();
            //$('body').removeClass('bg-init');
            console.log('event -> inStart single');
          }).one('animsition.inEnd', function() {
            console.log('event -> inEnd single');
          });
          //end
        }
      },
      // 
      'people': {
        init: function() {
          animate_logo();
          //overlay video
          $('.modal-button').click(function(e) {
            e.stopPropagation();
            //open the modal
            $('#myModal').modal('show');
            ///$("#modal-overlay").addClass("active");
            //grab the vimeo id value
            var number = $(this).data('slide-index');
            /*
			$('.slider.single-item').css({display: 'inline-block'}).animate({"opacity": 1,'min-height': "450px"}, 'slow').html(
            '<div class="rectangle-bounce"></div>'
          );
		  */
            //start ajax request
            // $.getJSON("/wp-json/posts?type=projects&filter[posts_per_page]=100&filter[p]="+ID+"",
            $.ajax({
              url: "/wp-json/posts?type=page&filter[posts_per_page]=100&filter[p]=21",
              type: "GET",
              //data: { "id" : id},
              dataType: "json",
              contentType: "application/json; charset=utf-8",
              beforeSend: function(data) {
                $('.bxslider').css({
                  display: 'block'
                }).animate({
                  "opacity": 1,
                  'min-height': "667px"
                }, 'fast').html(
                  '<div class="animsition-loading"></div>');
                // null beforeSend to prevent recursive ajax call
              },
              complete: function(data) {},
              success: function(data) {
                //data = $.parseJSON(data);
                //console.log(data);
                //console.log (number);
                //mapData.custom_meta.address
                setTimeout(function() {
                  for (var i = 0, len = data[0].custom_meta
                    .length; i < len; i++) {
                    // console.log(data[0].custom_meta[i]);
                    console.log(data[0].custom_meta[i].img);
                    var htmlData =
                      '<li><figure><img title="' + data[0].custom_meta[
                        i].name + '" src="' + data[0].custom_meta[
                        i].img +
                      '" class="img-responsive" /></figure>' +
                      '<div class="desc-txt">' + '<h3>' +
                      data[0].custom_meta[i].name + '</h3>' +
                      '<ul>' + '<li>' + data[0].custom_meta[
                        i].position + '</li>' + '<li>' +
                      data[0].custom_meta[i].sub_position +
                      '</li>' + '</ul>' + '<p>' + data[0].custom_meta[
                        i].bio + '</p>' +
                      //'<a href="'+data[0].custom_meta[i].bio_link+'" class="readmore">Read More</a>'+
                      '</div></li>';
                    //'<h3>'+data[0].custom_meta[i].img+'</h3>
                    $('.animsition-loading').remove();
                    $(".bxslider").append(htmlData);
                    //console.log (data[0].custom_meta.length);
                    //console.log(data[0].custom_meta);
                  }
                }, 2200);
                //end loop
                //$(document).ajaxComplete(function() {	
                setTimeout(function() {
                  peopleSlider = $('.bxslider').bxSlider({
                    touchEnabled: true,
                    //pager: false,
                    nextSelector: '#slider-next',
                    prevSelector: '#slider-prev',
                    nextText: 'Onward →',
                    prevText: '← Go back',
                    pagerCustom: '#people-pager',
                    startSlide: number,
                    onSliderLoad: function(currentIndex) {
                      //$(".slider-txt").html($('.bxslider li').eq(currentIndex).find("img").attr("title"));
                      ///$('.bxslider').goToSlide(number);
                      //console.log (number);
                    },
                    onSlideNext: function($slideElement,
                      oldIndex, newIndex) {
                      $("#slider-next .bx-next").html(
                        $slideElement.find("img").attr(
                          "title"));
                    },
                    onSlidePrev: function($slideElement,
                      oldIndex, newIndex) {
                      $("#slider-prev .bx-prev").html(
                        $slideElement.find("img").attr(
                          "title"));
                    }
                  });
                  //});
                  //do something special
                }, 2200);
                $('.reset-modal').click(function() {
                  peopleSlider.destroySlider();
                });
              }
            });
            //end ajax
          });
          //end of json ajx call
          //reset modal overlay
          //slider = $('.bxslider').bxSlider();
          //$peopleSlider  = $('.bxslider').bxSlider();
          setTimeout(function() {
            $('.hero-height').addClass('active');
          }, 300);
          $(window).on("resize", function() {
            $('.hero-height').height($(window).height() - 380);
            // Invoke the resize event immediately
          }).resize();
          $('.js-dropdown-item').dropdownLayer({
            'elemSelector': "js-dropdown-item",
            'containerClass': "js-dropdown-items",
            'descriptionClass': "js-description",
            'arrowClass': "js-dropdown-arrow",
            'dropdownClass': "js-dropdown",
            'dropdownContentClass': "js-dropdown-content",
            'disableDropdownClass': "js-dropdown-disable",
            'useSlideUp': true,
            'slideUpSpeed': 300,
            'useSlideDown': true,
            'slideDownSpeed': 300,
            'callOnCompleteHide': function() {},
            'callOnCompleteShow': function() {
              $("body, html").animate({
                scrollTop: $('.js-dropdown-item.active').offset()
                  .top + 200
              }, 300);
            }
          });
          var wow = new WOW({
            boxClass: 'wow', // animated element css class (default is wow)
            animateClass: 'animated', // animation css class (default is animated)
            mobile: false, // trigger animations on mobile devices (default is true)
            live: true, // act on asynchronously loaded content (default is true)
            callback: function(box) {
              // the callback is fired every time an animation is started
              // the argument that is passed in is the DOM node being animated
            },
            scrollContainer: null // optional scroll container selector, otherwise use window
          });
          wow.init();
        }
      },
      'about': {
        init: function() {
          animate_logo();
          var waypoint = new Waypoint({
            element: document.getElementById('universe'),
            handler: function(direction) {
              if (direction === 'down') {
                $(".cover-bg").addClass("activate");
              }
            },
            offset: 150
          });
          $('.quote-slider').slick({
            dots: false,
            autoplay: true,
            speed: 500,
            fade: true,
            autoplaySpeed: 5000,
            arrows: false,
            cssEase: 'linear'
          });
          setTimeout(function() {
            $('.hero-height').addClass('active');
          }, 300);
          $(window).on("resize", function() {
            $('.hero-height').height($(window).height() - 360);
            // Invoke the resize event immediately
          }).resize();
          var wow = new WOW({
            boxClass: 'wow', // animated element css class (default is wow)
            animateClass: 'animated', // animation css class (default is animated)
            mobile: false, // trigger animations on mobile devices (default is true)
            live: true, // act on asynchronously loaded content (default is true)
            callback: function(box) {
              // the callback is fired every time an animation is started
              // the argument that is passed in is the DOM node being animated
            },
            scrollContainer: null // optional scroll container selector, otherwise use window
          });
          wow.init();
        }
      },
      'contact': {
        init: function() {
          animate_logo();
          setTimeout(function() {
            $('.hero-height').addClass('active');
          }, 300);
          $(window).on("resize", function() {
            $('.hero-height').height($(window).height() - 360);
            // Invoke the resize event immediately
          }).resize();
          var wow = new WOW({
            boxClass: 'wow', // animated element css class (default is wow)
            animateClass: 'animated', // animation css class (default is animated)
            mobile: false, // trigger animations on mobile devices (default is true)
            live: true, // act on asynchronously loaded content (default is true)
            callback: function(box) {
              // the callback is fired every time an animation is started
              // the argument that is passed in is the DOM node being animated
            },
            scrollContainer: null // optional scroll container selector, otherwise use window
          });
          wow.init();
          //end wow
          /* jshint ignore:start */
          // (function(){ window.onload = function() {
          //start of map
          function initialize() {
            var latlng = new google.maps.LatLng(34.083184, -118.324045);
            var settings = {
              zoom: 14,
              disableDefaultUI: false,
              center: latlng,
              scrollwheel: false,
              navigationControl: true,
              scaleControl: false,
              streetViewControl: false,
              draggable: false,
              mapTypeControl: false,
              mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
              },
              navigationControlOptions: {
                style: google.maps.NavigationControlStyle.SMALL
              },
              mapTypeId: google.maps.MapTypeId.ROADMAP
            };
            var map = new google.maps.Map(document.getElementById("map"),
              settings);
            google.maps.event.addDomListener(window, 'resize');
            // Creating the JSON data
            var json = [{
              "title": "Bluegiant",
              "lat": 34.083184,
              "lng": -118.324045,
              "featured_image": "",
              "address": "5700 Melrose Avenue, Suite 403 <br>Los Angeles, CA 90038 <br><a href='tel:'>323.380.7450</a>"
                    }];
            // Looping through the JSON data
            for (var i = 0, length = json.length; i < length; i++) {
              var data = json[i],
                latLng = new google.maps.LatLng(data.lat, data.lng);
              // Creating a global infoWindow object that will be reused by all markers
              //var infoWindow = new google.maps.InfoWindow();
              var contentString = '<div id="map-content">' +
                '<div id="siteNotice">' + '<h1 class="firstHeading">' +
                data.title + '</h1>' + '<div id="bodyContent">' +
                '<address>' + data.address + '</address>' + '</div>' +
                '</div>';
              var icon = {
                url: "" + dir_url + "/dist/images/map-pin.png", // url
                scaledSize: new google.maps.Size(43, 55), // scaled size
              };
              //Creating a marker and putting it on the map
              var marker = new google.maps.Marker({
                position: latLng,
                map: map,
                title: data.title,
                icon: icon,
              });
              var infowindow = new google.maps.InfoWindow({
                content: contentString,
                maxWidth: 500,
                //pixelOffset: 300,
                //pixelOffset: new google.maps.Size(0, 60)
              });
              (function(marker, contentString) {
                // Attaching a click event to the current marker
                google.maps.event.addListener(marker, "click",
                  function(e) {
                    infowindow.setContent(contentString);
                    infowindow.open(map, marker);
                  });
              })(marker, contentString);
              red_road_styles = [{
                "featureType": "all",
                "elementType": "labels.text.fill",
                "stylers": [{
                  "saturation": 36
                            }, {
                  "color": "#000000"
                            }, {
                  "lightness": 40
                            }]
                        }, {
                "featureType": "all",
                "elementType": "labels.text.stroke",
                "stylers": [{
                  "visibility": "on"
                            }, {
                  "color": "#000000"
                            }, {
                  "lightness": 16
                            }]
                        }, {
                "featureType": "all",
                "elementType": "labels.icon",
                "stylers": [{
                  "visibility": "off"
                            }]
                        }, {
                "featureType": "administrative",
                "elementType": "geometry.fill",
                "stylers": [{
                  "color": "#000000"
                            }, {
                  "lightness": 20
                            }]
                        }, {
                "featureType": "administrative",
                "elementType": "geometry.stroke",
                "stylers": [{
                  "color": "#000000"
                            }, {
                  "lightness": 17
                            }, {
                  "weight": 1.2
                            }]
                        }, {
                "featureType": "administrative.locality",
                "elementType": "all",
                "stylers": [{
                  "visibility": "simplified"
                            }, {
                  "lightness": "-96"
                            }, {
                  "weight": "1.52"
                            }]
                        }, {
                "featureType": "administrative.locality",
                "elementType": "labels.text",
                "stylers": [{
                  "weight": "1.78"
                            }]
                        }, {
                "featureType": "administrative.neighborhood",
                "elementType": "all",
                "stylers": [{
                  "visibility": "off"
                            }, {
                  "lightness": "-92"
                            }]
                        }, {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [{
                  "lightness": "-38"
                            }]
                        }, {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [{
                  "color": "#000000"
                            }, {
                  "lightness": 20
                            }]
                        }, {
                "featureType": "landscape.man_made",
                "elementType": "geometry.fill",
                "stylers": [{
                  "visibility": "off"
                            }]
                        }, {
                "featureType": "landscape.natural.landcover",
                "elementType": "geometry.stroke",
                "stylers": [{
                  "visibility": "off"
                            }]
                        }, {
                "featureType": "landscape.natural.terrain",
                "elementType": "all",
                "stylers": [{
                  "visibility": "off"
                            }]
                        }, {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [{
                  "color": "#000000"
                            }, {
                  "lightness": 21
                            }]
                        }, {
                "featureType": "road",
                "elementType": "all",
                "stylers": [{
                  "visibility": "on"
                            }, {
                  "gamma": "1.14"
                            }, {
                  "hue": "#ff0000"
                            }]
                        }, {
                "featureType": "road",
                "elementType": "labels.icon",
                "stylers": [{
                  "visibility": "off"
                            }]
                        }, {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [{
                  "color": "#000000"
                            }, {
                  "lightness": "14"
                            }]
                        }, {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [{
                  "color": "#000000"
                            }, {
                  "lightness": 29
                            }, {
                  "weight": 0.2
                            }]
                        }, {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [{
                  "color": "#000000"
                            }, {
                  "lightness": 18
                            }]
                        }, {
                "featureType": "road.arterial",
                "elementType": "geometry.fill",
                "stylers": [{
                  "visibility": "on"
                            }, {
                  "lightness": "-25"
                            }]
                        }, {
                "featureType": "road.local",
                "elementType": "all",
                "stylers": [{
                  "visibility": "on"
                            }, {
                  "lightness": "-4"
                            }]
                        }, {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [{
                  "color": "#000000"
                            }, {
                  "lightness": 16
                            }]
                        }, {
                "featureType": "road.local",
                "elementType": "geometry.fill",
                "stylers": [{
                  "lightness": "-6"
                            }, {
                  "visibility": "on"
                            }]
                        }, {
                "featureType": "road.local",
                "elementType": "labels",
                "stylers": [{
                  "lightness": "-14"
                            }, {
                  "visibility": "simplified"
                            }]
                        }, {
                "featureType": "transit",
                "elementType": "geometry",
                "stylers": [{
                  "color": "#000000"
                            }, {
                  "lightness": 19
                            }]
                        }, {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [{
                  "color": "#0f1112"
                            }, {
                  "lightness": 17
                            }]
                        }];
              map.setOptions({
                styles: red_road_styles
              });
            }
          }
          google.maps.event.addDomListener(window, 'resize', initialize);
          google.maps.event.addDomListener(window, 'load', initialize);
          // };
          //})();
          /* jshint ignore:end */
        }
      },
      'page_child': {
        init: function() {
          animate_logo();
          //wow
          var wow = new WOW({
            boxClass: 'wow', // animated element css class (default is wow)
            animateClass: 'animated', // animation css class (default is animated)
            mobile: false, // trigger animations on mobile devices (default is true)
            live: true, // act on asynchronously loaded content (default is true)
            callback: function(box) {
              // the callback is fired every time an animation is started
              // the argument that is passed in is the DOM node being animated
            },
            scrollContainer: null // optional scroll container selector, otherwise use window
          });
          wow.init();
        }
      }
    };
    //end
    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
      fire: function(func, funcname, args) {
        var fire;
        var namespace = Sage;
        funcname = (funcname === undefined) ? 'init' : funcname;
        fire = func !== '';
        fire = fire && namespace[func];
        fire = fire && typeof namespace[func][funcname] === 'function';
        if (fire) {
          namespace[func][funcname](args);
        }
      },
      loadEvents: function() {
        // Fire common init JS
        UTIL.fire('common');
        // Fire page-specific init JS, and then finalize JS
        $.each(document.body.className.replace(/-/g, '_').split(/\s+/),
          function(i, classnm) {
            UTIL.fire(classnm);
            UTIL.fire(classnm, 'finalize');
          });
        // Fire common finalize JS
        UTIL.fire('common', 'finalize');
      }
    };
    // Load Events
    $(document).ready(UTIL.loadEvents);
  })(jQuery); // Fully reference jQuery after this point.